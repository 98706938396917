<template>
  <div class="e-container">
    <div class="e-brand-container">
      <el-button type="text" @click="goExamList" class="text">&lt;返回</el-button>
    </div>
    <template v-if="type == 2">
      <div class="e-exam-container">
        <div class="e-exam-name">{{ exam?.name }}考试成绩单</div>
      </div>
      <div class="exam-score-container">
        <table>
          <thead>
            <tr>
              <th colspan="2">安徽省人工智能学会考试成绩单</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>考试名称</td>
              <td>{{ exam?.name || '--' }}</td>
            </tr>
            <tr>
              <td>考试形式</td>
              <td>机考</td>
            </tr>
            <tr>
              <td>考试时间</td>
              <td>{{ exam?.startDttm || '--' }}</td>
            </tr>
            <tr>
              <td>准考证号</td>
              <td>{{ score?.examineeNo || '--' }}</td>
            </tr>
            <tr>
              <td>考生姓名</td>
              <td>{{ score?.name || '--' }}</td>
            </tr>
            <tr>
              <td>作答时长</td>
              <td>{{ score?.costTime || '--' }}</td>
            </tr>
            <tr>
              <td>考试得分</td>
              <td>{{ score?.score === undefined ? '--' : score?.score + '分' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="showGoAnalysisBtn()">
        <el-button type="primary" plain @click="goExamAnswerDetail">查看作答详情</el-button>
      </div>
    </template>
    <template v-if="type == 1">
      <div class="e-exam-container">
        <div class="e-exam-name">{{ exam?.name }}</div>
      </div>
      <div class="exam-score-container">
        您的答卷已提交成功，请尽快离开考场。
        <!-- <table>
        <thead>
          <tr>
            <th colspan="2">安徽省人工智能学会考试成绩单</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>考试名称</td>
            <td>{{ exam?.name || '--' }}</td>
          </tr>
          <tr>
            <td>考试形式</td>
            <td>机考</td>
          </tr>
          <tr>
            <td>考试时间</td>
            <td>{{ exam?.startDttm || '--' }}</td>
          </tr>
          <tr>
            <td>准考证号</td>
            <td>{{ score?.examineeNo || '--' }}</td>
          </tr>
          <tr>
            <td>考生姓名</td>
            <td>{{ score?.name || '--' }}</td>
          </tr>
          <tr>
            <td>作答时长</td>
            <td>{{ score?.costTime || '--'}}</td>
          </tr>
          <tr>
            <td>考试得分</td>
            <td>{{ score?.score === undefined ? '--' : score?.score+'分'}}</td>
          </tr>
        </tbody>
      </table> -->
      </div>
      <!-- <div v-if="showGoAnalysisBtn()">
      <el-button type="primary" plain @click="goExamAnswerDetail">查看作答详情</el-button>
    </div> -->
    </template>


  </div>
</template>

<script>
export default {
  name: 'studentScore',
  data() {
    return {
      examTypeMap: {
        1: '正式考试',
        2: '模拟考试',
      },
      examId: null,
      exam: null,
      score: null,
      type: null,
    };
  },
  beforeMount() {
    const { id } = this.$route.query;
    if (!id) {
      this.$router.go(-1);
      return;
    }
    this.examId = id;
  },
  async mounted() {
    this.exam = await this.queryExam({ id: this.examId });
    this.type = this.exam.type;
    if (this.type == 2) {
      this.score = await this.queryUserExamScore({ examId: this.examId });
    }
  },
  methods: {
    async queryExam(data) {
      const examResult = await this.Api.student.queryExam(data);
      return examResult.data;
    },
    async queryUserExamScore(data) {
      const scoreResult = await this.Api.student.queryUserExamScore(data);
      const score = scoreResult.data;
      return score;
    },
    showGoAnalysisBtn() {
      return this.exam?.type === 2;
    },
    goExamList() {
      this.$router.push({
        path: '/student/exams',
      });
    },
    goExamAnswerDetail() {
      this.$router.push({
        path: '/student/examAnalysis',
        query: {
          id: this.examId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.e-container {
  width: 1200px;
  height: 100%;
  padding: 25px;
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  row-gap: 45px;
  align-items: center;
  box-sizing: border-box;
  margin: 0 auto;
}

.e-brand-container {
  width: inherit;
  height: 40px;
  position: fixed;
  display: flex;
  align-items: center;
  padding-left: 40px;
  cursor: pointer;

  .text {
    font-size: 16px;
  }
}

.e-exam-container {
  height: 40px;
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #d3d4d6;

  .e-exam-name {
    font-size: 27px;
    font-weight: 500;
  }
}

.exam-score-container {
  table {
    width: 750px;
    border-spacing: 0px;
    border-collapse: collapse;

    thead {
      text-align: left;
      color: #FFF;
      background-color: #005dcc;
    }

    tr {

      th,
      td {
        padding: 10px 20px;
        border: 1px solid #d3d4d6;
      }
    }

    tbody {
      tr:nth-child(odd) {
        background-color: #f1f1f1;
      }

      tr:nth-child(even) {
        background-color: #ffffff;
      }

      tr>td:nth-child(odd) {
        width: 260px;
        text-align: right;
        font-weight: 600;
      }
    }
  }
}
</style>
